//--------------------------------\\
//--- Validation de formulaire ---\\
//--------------------------------\\

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Dans ce fichier, on retrouve toutes les validations de formulaire, les messages d'erreur et le formatage.
// Pour que tout fonctionne, il faut les node-modules formatter et jquery-validation déjà.
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Importation ----------------------------------------------------------------------------------------------------
import $ from 'jquery'
//-----------------------------------------------------------------------------------------------------------------


// LES FONCTIONS
// Fonction qui gère le label des input
export function fixInput() {
  // Si le focus est sur le champ, on enlève la classe vide pour que le label se déplace
  $('input, textarea').focusin(function() {
    $(this).removeClass('notEmpty')
  })

  // Si le champ est vide, on replace le label à sa position d'origine au focusout
  $('input, textarea').focusout(function() {
    ($(this).val() != '' ? $(this).addClass('notEmpty') : $(this).removeClass('notEmpty'))
  })
}


// Fonction permettant de modifier la hauteur d'un textarea par rapport à son contenu
export function textareaHeight() {
  $('body').on('change keydown keyup keydown paste cut', 'textarea', function() {
    $(this).height(0).height(this.scrollHeight)
  }).find('textarea').change()
}



// LES MESSAGES
// Fonction contenant tous les messages
export function getMessages() {
  if($('html')[0].lang == 'en') {
    return {
      required: 'This field is required.',
      select: 'Please chose an option.',
      email: 'Please enter a valid email address.',
      phone: 'Please enter a valid phone number.',
      postale: 'Please enter a valid ZIP code.',
      date: 'Please enter a valid date.',
      accept: 'The file must be a pdf file.',
      file: 'Please provide a adequate file.'
    }
  } else {
    return {
      required: 'Ce champ est obligatoire.',
      select: 'Veuillez sélectionner une option.',
      email: 'Veuillez fournir une adresse électronique valide.',
      phone: 'Veuillez fournir un numéro de téléphone valide.',
      postale: 'Veuillez fournir un code postal valide.',
      date: 'Veuillez fournir une date valide.',
      accept: 'Le fichier doit être un document pdf.',
      file: 'Veuillez lier le fichier adéquat pour ce champ.'
    }
  }
}



// LES RÈGLES __________
// Règle du email
export function validationEmail() {
  jQuery.validator.addMethod('courriel', function(value, element) {
    return this.optional(element) || /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/.test(value)
  })
}

export function validationFilesize() {
  // Si aucuns champs n'ont de poids maximal, mettre la limite à 2mo
  var maxFileSize = '2Mo'
  var inputWithSize = $('form').find('input[data-readable-size]')
  if(inputWithSize.length){ var maxFileSize = inputWithSize.attr('data-readable-size') }

  jQuery.validator.addMethod('filesize', function (value, element, param) {
    return this.optional(element) || (element.files[0].size <= param)
  }, 'La taille du fichier doit être inférieure à '+maxFileSize);
}

// LES FORMATS __________
// Format du téléphone
export function formatPhone(element) {
  $(element).mask('(000) 000-0000')
}

// Format du code postal
export function formatCodePostal(element) {
  $(element).mask('ABA BAB', {'translation': {
    A: {pattern: /[A-Za-z]/},
    B: {pattern: /[0-9]/}
  }})
}

// Format de la date
export function formatDate(element) {
  $(element).mask('00/00/00')
}



// LES VALIDATIONS __________
// Fonction exécutant la validation de chaque formulaire
export function formValidation(form, submit, rules, messages, handler) {
  $.validator.setDefaults({
    ignore: []
  })
  $(form).validate({
    debug: false,
    rules: rules,
    messages: messages,
    errorElement: 'div',
    errorPlacement: function(error, element) {
      var placement = $(element).data('error')
      if (element.attr('type') == 'radio') {
        error.insertBefore(element.closest('.radiobox-field').find('.errorblock'))
      } else if (element.attr('type') == 'checkbox') {
        error.insertBefore(element.closest('.checkbox-field').find('.errorblock'))
      } else if (placement) {
        $(placement).append(error)
      } else {
        error.insertBefore(element)
      }
    },
    submitHandler: function() {
      if (typeof handler != 'undefined') {
        handler()
        return false
      }
      return true
    }
  })
  $(submit).click(function() {
    $(form).submit()
  })
}

//**********  Validation du formulaire contact
export function contactForm(form, submit) {
  var m = getMessages()

  // Si aucuns champs n'ont de poids maximal, mettre la limite à 2mo
  var maxFileSize = 2097152 //2mo
  var inputWithSize = $(form).find('input[size]')
  if(inputWithSize.length){ var maxFileSize = inputWithSize.attr('size') }

  formatPhone('#contactTelephone')
  validationFilesize()
  var validTypeList = [
      'application/pdf',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  ];
  var validTypes = validTypeList.join(',')
  var rules = {
    nom:            { required: true },
    prenom: 		    { required: true },
    courriel:       { required: true, email: true },
    telephone:      { required: true, minlength: 14 },
    message: 		    { required: true },
    'multiFile[]':  { accept: validTypes, filesize: maxFileSize },
    'consentToPolicy[]': { required: true },
  };
  var messages = {
    nom:            { required: m.required },
    prenom:         { required: m.required },
    courriel:       { required: m.required, email: m.email },
    telephone:      { required: m.required, minlength: m.phone },
    message: 	    	{ required: m.required },
    'multiFile[]':  { accept: m.accept },
    'consentToPolicy[]': { required: m.required },
  };
  formValidation(form, submit, rules, messages);
}

// Validation du formulaire d'emploi'
export function formEmploi() {
  validationEmail()
  validationFilesize()
  formatPhone('#emploiTelephone')
  var m = getMessages()
  var rules = {
    nom: { required: true },
    prenom: { required: true },
    courriel: { required: true, courriel: true },
    motivation: { accept: "pdf", filesize: 5242880 },
    cv: { required: true, accept: "pdf", filesize: 5242880 },
    telephone: { required: true, minlength: 14 },
    'consentToPolicy[]': { required: true },
  }
  var messages = {
    nom: { required: m.required },
    prenom: { required: m.required },
    courriel: { required: m.required, email: m.email },
    cv: { required: m.file, accept: m.accept },
    motivation: { accept: m.accept },
    telephone: { required: m.required, minlength: m.phone },
    'consentToPolicy[]': { required: m.required },
  }

  formValidation('#formEmploi', '#submitEmploi', rules, messages)
}

export function changeLabelUpload() {
  $('.file-field input[type=file]').change(function() {
      $(this).next('label').find('span').text($(this).val().split(/(\\|\/)/g).pop())
  })
}

// Transforme les selects
export function magicSelect() {
  $(".chosen-select").chosen({ disable_search: true })
}

// Gérer l'ajout infini de champs d'upload
export function manageMultiUpload() {

  $('.file-field.multi .uploadBtnWrapper .close').click(function() {
    $(this).parent().remove()
  })

  $('.file-field.multi input[type=file]').change(function() {
      var btn = $(this).parent()
      var container = btn.parent()

      if($(this).get(0).files.length > 0){
        btn.addClass('notEmpty')

        //Si c'est le dernier bouton, en ajouter un autre
        if(btn.is(':last-child')){
          var id = makeid()

          // Dupliquer le premier bouton qui nous sert de modèle et le dupliquer à la fin
          var model = container.children().first()
          model.clone().appendTo(container)

          // Resetter le nouveau input file qui vient d'être dupliqué
          var last = container.children().last()
          last.find('span').empty()
          last.removeClass('notEmpty')
          last.find('input').attr('id',id)
          last.find('input').val('')
          last.find('label').attr('for',id)
          last.find('#multiFile-error').remove()

          manageMultiUpload()
          changeLabelUpload()
          validationFilesize()
        }
      }else{
        btn.removeClass('notEmpty')
      }
  })
}

//Générer un ID unique
function makeid() {
  var result           = '';
  var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
  var charactersLength = characters.length;
  for ( var i = 0; i < 10; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
 }
