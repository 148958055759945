//------------------------\\
//---- Classe Overlay ----\\
//------------------------\\

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Classe permettant d'ajouter une classe au HTML pouvant ouvrir un overlay
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Importation ----------------------------------------------------------------------------------------------------
import $ from 'jquery'
import UI from './UI.js'
//-----------------------------------------------------------------------------------------------------------------


export class Overlay {

  constructor(nameOverlay, targetedElement, targetedClass, closingElements = '', noscroll = true, backToTop = true) {
    this.element         = targetedElement
    this.class           = targetedClass
    this.closingElements = closingElements
    this.noscroll        = noscroll
    this.backToTop       = backToTop
    this.width           = $(window).width()

    // Toggle de ouvrir et fermer l'overlay
    $(this.element).on('click', () => {
      if(!$('html').hasClass(this.class))
        this.open()
      else
        this.close()
    })

    // Fermer l'overlay au clique des éléments de fermeture
    $(this.closingElements + ', #overlay').on('click', () => {
      this.close()
    })

    // Ferme l'overlay au resize de la fenêtre
    $(window).on( 'resize', () => {
      var newWidth = $(window).width()
      if(newWidth == this.width) { return } else {
        this.width = $(window).width()
        this.close()
      }
    })

    // Ajoute l'objet à UI pour qu'elle soit disponible de l'extérieur
    UI.add( nameOverlay , this )
  }


  // Permet d'ouvrir un overlay
  open() {
    $('html').addClass(this.class)
    if(this.noscroll) this.addNoScroll()
  }


  // Permet de fermer un overlay
  close(){
    if($('html').hasClass(this.class)) {
      $('html').removeClass(this.class)
      if(!$(this).hasClass('keepNoScoll') && this.noscroll)
        this.removeNoScroll()
    }
  }


  // Ajoute le noscroll lorsqu'un overlay est ouvert empêchant le scroll dans la page
  addNoScroll() {
    if(this.backToTop) $(document).scrollTop(0)
    if($(document).height() > $(window).height()) {
      var scrollTop = ($('html').scrollTop()) ? $('html').scrollTop() : $('body').scrollTop()
      $('html').addClass('noscroll').css('top',-scrollTop)
    }
  }


  // Retire le noscroll
  removeNoScroll() {
    if(this.backToTop) {
      $('html').removeClass('noscroll')
      $('html,body').scrollTop(0)
    } else {
      var scrollPosition = parseInt($('html').css('top'))
      $('html').removeClass('noscroll')
      $('html,body').scrollTop(-scrollPosition)
    }
  }
}
