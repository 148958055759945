//-----------------------------\\
//--- Transition avec barba ---\\
//-----------------------------\\

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// C'est ici que les transition entre les page sont gérées, on y retrouve les différentes views spécifiques à
// chaque page. Tout commence avec la fonction doOnce.
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Importation ----------------------------------------------------------------------------------------------------
import Barba from 'barba.js'
import $ from 'jquery'
import { setView } from './views.js'
import { Loader } from './classes/loader.js'
//-----------------------------------------------------------------------------------------------------------------


// Cette fonction Gère la transition entre les page
export function initPageTransition() {
  Barba.Pjax.cacheEnabled = false
  Barba.Pjax.Dom.wrapperId = 'transition-wrapper'
  Barba.Pjax.Dom.containerClass = 'transition-container'
  Barba.Pjax.ignoreClassLink = 'no-transition'
  setTransition()
  setView()
  Barba.Pjax.start()
  Barba.Dispatcher.on('initStateChange', function (currentStatus) {
    if( typeof gtag === 'function' ) gtag('config', 'UA-41917822-52', { 'page_path': location.pathname }) // Google tag
  })
}


// Cette fonctions exécute la transition entre les pages
function setTransition() {
  var preloader = new Loader()
  var FadeTransition = Barba.BaseTransition.extend({
    start: function() {
      Promise
        .all([this.newContainerLoading, this.fadeOut()])
        .then(this.fadeIn.bind(this))
    },
    fadeOut: function() {
      preloader.reset()
      $('.hamburger').removeClass('is-active')
      return $('html').addClass('transition').delay(1800).promise()
    },
    fadeIn: function() {
      preloader.start()
      if (!window.location.hash) window.scrollTo(0, 0)
      preloader.setCompleteCallback([ () => {
        $('html').removeClass('transition')
        $.fn.BLK_scrollfire.init()
        this.done()
      }])
    }
  })

  Barba.Pjax.getTransition = function() {
    return FadeTransition
  }
}
