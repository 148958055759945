//-------------------------\\
//--- Classes des views ---\\
//-------------------------\\

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Dans ce fichier, on retrouve les différentes fonctions utilisées par les views dans transition.js. Ces fonction
// vont servir à appeler les scripts spécifiques ou les plugins utilisés dans les page web. Les fonctions appelées
// sont réparties dans différents fichiers (slicks, overlay), afin de garder le tout le plus structuré possible.
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Importation ----------------------------------------------------------------------------------------------------
import $ from 'jquery'
import { overlayDropDown, overlayMenu } from './functions/overlay.js'
import { noSamePageDestination, removeHoverOnMobile, tiroirs, magicSticky } from './functions/functions.js'
import { slickHome, slickJobs, slickTestimonials, destroySlick } from './functions/slicks.js'
import { initShareModal } from './functions/shareModal.js'
import { fixInput, textareaHeight, contactForm, changeLabelUpload, magicSelect, formEmploi, manageMultiUpload } from './functions/forms.js'
import { Parallax } from './classes/Parallax.js'
//-----------------------------------------------------------------------------------------------------------------


// Initialisation sur toutes les pages
export class View {
  static init() {
    overlayDropDown()
    overlayMenu()
    noSamePageDestination()
    removeHoverOnMobile()
    magicSticky()
    initShareModal()
    $.fn.BLK_scrollfire()
    window.parallax = new Parallax()
  }

  static leave() {

  }
}


// Classe initialisant les scripts présents dans la page d'accueil
export class Home extends View {
  static initHome() {
    this.init()
    slickHome();
    slickJobs();
  }

  static leaveHome() {
    this.leave()
    destroySlick('.homeSlides,.jobImages',1000);
  }
}

// Classe initialisant les scripts présents dans la page d'accueil
export class Generic extends View {
  static initGeneric() {
    this.init()
    tiroirs();
  }

  static leaveGeneric() {
    this.leave()
  }
}

// Classe initialisant les scripts présents dans la page la difference APF
export class Difference extends View {
  static initDifference() {
    this.init()
    slickTestimonials()
    tiroirs();
  }

  static leaveDifference() {
    this.leave()
    destroySlick('.testimonialImages, .testimonialTexts',1000);
  }
}

// Classe initialisant les scripts présents dans la page Equipement APF
export class Equipement extends View {
  static initEquipement() {
    this.init()
  }

  static leaveEquipement() {
    this.leave()
  }
}

// Classe initialisant les scripts présents dans la page carriere
export class Carriere extends View {
  static initCarriere() {
    this.init()
    changeLabelUpload()
    magicSelect()
    fixInput()
    formEmploi()
  }

  static leaveCarriere() {
    this.leave()
  }
}


// Classe initialisant les scripts présents dans la page de contact
export class Contact extends View {
  static initContact() {
    this.init()
    fixInput()
    textareaHeight()
    contactForm('#formContact','#submitContact')
    changeLabelUpload()
    manageMultiUpload()
  }

  static leaveContact() {
    this.leave()
  }
}