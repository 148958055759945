//---------------------------\\
//--- Fonctions générales ---\\
//---------------------------\\

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Dans ce fichier on retrouve les fonctions générales qu'on ne peut pas vraiment catégoriser
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Importation ----------------------------------------------------------------------------------------------------
import $ from 'jquery'
import Stickyfill from 'stickyfilljs'
//-----------------------------------------------------------------------------------------------------------------

// Empêche une transition si la destination est la page actuelle
export function noSamePageDestination() {
  var links = document.querySelectorAll('a[href]')
  var cbk = function(e) {
    if (e.currentTarget.href === window.location.href) {
      e.preventDefault()
      e.stopPropagation()
      $("html, body").animate({ scrollTop: 0 }, 600);
    }
  }
  for (var i = 0; i < links.length; i++) {
    links[i].addEventListener('click', cbk)
  }
}


// Retire tous les effets d'over sur le mobile
export function removeHoverOnMobile() {
  if (/Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    try { // prevent exception on browsers not supporting DOM styleSheets properly
      for (var si in document.styleSheets) {
        var styleSheet = document.styleSheets[si]
        if (!styleSheet.rules) continue

        for (var ri = styleSheet.rules.length - 1; ri >= 0; ri--) {
          if (!styleSheet.rules[ri].selectorText) continue
          if (styleSheet.rules[ri].selectorText.match(':hover')) styleSheet.deleteRule(ri)
        }
      }
    } catch (ex) {}
  }
}


// Fonction initialisant les tiroirs
export function tiroirs() {
  $('.tiroirs > li .toggleTiroir').on('click', function() {
    var ouvert = $(this).parent().hasClass('open')
    $('.tiroirs li.open .tiroir').animate({ height: 'hide' }, { duration: 400, specialEasing: { height: 'easeOutExpo' }})
    $('.tiroirs li.open').removeClass('open')
    if (!ouvert) {
      var chosen = this
      setTimeout(function() { $(chosen).parent().addClass('open') }, 0)
      $('.tiroir', $(this).parent()).stop(true, false).animate({ height: 'show' }, { duration: 400, specialEasing: { height: 'easeOutExpo' }})
    }
    return false
  })
}


// Fonction permettant de défiller d'un block à un autre à l'aide d'un clique
export function scrollToBlock (depart, destination, duration, ajustement) {
	// Paramètres optionnels
	duration   = (typeof duration   === 'undefined') ? 600 : duration
	ajustement = (typeof ajustement === 'undefined') ? 0   : ajustement

	// Défilement au clique
	$(depart).on( 'click', function() {
    if($(window).width() <= 1024) ajustement = 0
		$.fn.BLK_smoothScroll.destroy()
    $('html, body').animate({
      scrollTop: ( destination != '' ? $(destination).offset().top + ajustement : 0 )
    }, duration, 'easeInOutExpo', function() {
      $.fn.BLK_smoothScroll()
  	})
  })
}

// Fonction pour les positions sticky dans IE
export function magicSticky() {
  Stickyfill.add($('.sticky'))
}

// Fonction à lancer quand une page de news est affiché en ajax
export function newsLoaded(data) {
	if (!data['hasMorePages']) $('#moreNewsBtn').hide();
	setTimeout( function() { $('html, body').animate({ scrollTop: $('.newspage' + data['currentPage']).offset().top - 150}, 600, 'easeInOutExpo', function() {});}, 250);
}