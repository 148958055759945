export function initShareModal() {

	if (typeof __sharethis__ !== 'undefined' && __sharethis__.config) {
		__sharethis__.init(__sharethis__.config);
	}

	$('.share').click(function() {
		// Setup link.
        var pageurl = window.location.href;
		$('#facebookShareBtn').data('url', pageurl);
		$('#facebookShareBtn').attr('data-url',pageurl);
		$('#twitterShareBtn').data('url', pageurl);
		$('#twitterShareBtn').attr('data-url', pageurl);
		$('#copyShareBtn').data('url', pageurl);
		$('#copyShareBtn').attr('data-url', pageurl);
		$('#emailShareBtn').attr("href", "mailto:?Subject=" + encodeURIComponent('Dévouvrez cette page sur le site de APF Villeneuve') + "&body=" + encodeURIComponent(pageurl));

		$('html').toggleClass('show-shareModal');
		return false;
	});

	$('#shareModalOverlay, #shareCloseBtn').click( function() {
		$('html').removeClass('show-shareModal');
		return false;
	});

	$('#copyShareBtn').click( function() {
		var link = $(this).data('url');
		copyTextToClipboard($(this).data('url'));
		alert('Un url a été copié sur votre presse-papiers.');
		return false;
	});

	function copyTextToClipboard(text) {
		if (!navigator.clipboard) {
			fallbackCopyTextToClipboard(text);
			return;
		}
	  navigator.clipboard.writeText(text).then(function() {
		console.log('Async: Copying to clipboard was successful!');
	  }, function(err) {
		console.error('Async: Could not copy text: ', err);
	  });
	}
	
	function fallbackCopyTextToClipboard(text) {
	  var textArea = document.createElement("textarea");
	  textArea.value = text;
	  document.body.appendChild(textArea);
	  textArea.focus();
	  textArea.select();
	
	  try
	  {
		var successful = document.execCommand('copy');
	  }
	  catch (err)
	  {
	  }
	
	  document.body.removeChild(textArea);
	  $(document).scrollTop(0);
	}


}