//-----------------\\
//--- Les views ---\\
//-----------------\\

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Ce fichier contient une seule fonction qui celle-ci contient toutes les views du site. Une views équivaux à
// une page web (homeView = la page d'accueil). On appelle seulement les classes dans global dans les vues, on
// n'appelle pas d'autres fonctions ou déclarer quoi que ce soit dans les vues afin de garder le tout clair.
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Importation ----------------------------------------------------------------------------------------------------
import Barba from 'barba.js'
import $ from 'jquery'

import {
  View, Home, Generic, Difference, Equipement, Contact, Carriere
} from './global.js'
//-----------------------------------------------------------------------------------------------------------------


export function setView() {

  // Page standart __________
  Barba.BaseView.extend({
    namespace: 'sdtView',
    onEnter:          function() {              },
    onEnterCompleted: function() { View.init()  },
    onLeave:          function() { View.leave() },
    onLeaveCompleted: function() {              }
  }).init()


  // Page d'accueil __________
  Barba.BaseView.extend({
    namespace: 'homeView',
    onEnter:          function() {},
    onEnterCompleted: function() { Home.initHome()  },
    onLeave:          function() { Home.leaveHome() },
    onLeaveCompleted: function() {}
  }).init()

  // Page d'générique __________
  Barba.BaseView.extend({
    namespace: 'genericView',
    onEnter:          function() {},
    onEnterCompleted: function() { Generic.initGeneric()  },
    onLeave:          function() { Generic.leaveGeneric() },
    onLeaveCompleted: function() {}
  }).init()

  // Page la différence APF __________
  Barba.BaseView.extend({
    namespace: 'differenceView',
    onEnter:          function() {},
    onEnterCompleted: function() { Difference.initDifference()  },
    onLeave:          function() { Difference.leaveDifference() },
    onLeaveCompleted: function() {}
  }).init()

  // Page equipement APF __________
  Barba.BaseView.extend({
    namespace: 'equipementView',
    onEnter:          function() {},
    onEnterCompleted: function() { Equipement.initEquipement()  },
    onLeave:          function() { Equipement.leaveEquipement() },
    onLeaveCompleted: function() {}
  }).init()

  // Page de carriere __________
  Barba.BaseView.extend({
    namespace: 'carriereView',
    onEnter:          function() {},
    onEnterCompleted: function() { Carriere.initCarriere()  },
    onLeave:          function() { Carriere.leaveCarriere() },
    onLeaveCompleted: function() {}
  }).init()

  // Page de contact __________
  Barba.BaseView.extend({
    namespace: 'contactView',
    onEnter:          function() {},
    onEnterCompleted: function() { Contact.initContact()  },
    onLeave:          function() { Contact.leaveContact() },
    onLeaveCompleted: function() {}
  }).init()

}
