//----------------------------\\
//---- Popup et sidepanel ----\\
//----------------------------\\

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Ici on retrouve tout ce qui a attrait au popup et au sidepanel (popup de connexion, menu mobile)
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Importation ----------------------------------------------------------------------------------------------------
import $ from 'jquery'
import { Overlay } from './../classes/overlay.js'
//-----------------------------------------------------------------------------------------------------------------


// Fonction gérant le menu drop down
export function overlayDropDown() {
	var activeMenuDropDown = ''
  var scrollTop

  var addDropDown = function(self) {
    if ($(document).height() > $(window).height() && !$('html').hasClass('showMenuDropDown')) {
      var scrollTop = ($('html').scrollTop()) ? $('html').scrollTop() : $('body').scrollTop()
      $('html').addClass('noscroll').css('top',-scrollTop)
    }
    $('.menuDropDown').removeClass('active')
    $('html').addClass('showMenuDropDown')
    $('html').removeClass(activeMenuDropDown)
    activeMenuDropDown = self.data('section')
    $('html').addClass(activeMenuDropDown)
    self.addClass('active')
  }

  var removeDropDown = function(self) {
    if($('html').hasClass('showMenuDropDown')) {
      $('html').removeClass( activeMenuDropDown )
      $('html').removeClass('showMenuDropDown')
      $('.menuDropDown').removeClass('active')
      var scrollPosition = parseInt($('html').css('top'))
      $('html').removeClass('noscroll')
      $('html,body').scrollTop(-scrollPosition)
    }
  }

	$('.menuDropDown').on('click', function() {
    if(!$(this).hasClass('active')) {
      addDropDown($(this))
    } else {
      removeDropDown($(this))
    }
	})

	$('#overlayDropDown .btn, .closeOverlay, .secondaryMenu a, .btnClose').on('click', function() {
		removeDropDown($(this))
	})
}


// Fonction gérant le menu mobile
export function overlayMenu() {
  $('.btnSubLvl1 > a').on('click', function(e) {
    // Fermeture de tout avant d'en ouvrir un autre
    if (!$(this).parent().hasClass('active')) {
      $('.btnSubLvl1').removeClass('active')
      $('.subLvl1').animate({ height: 'hide' }, { duration: 400, specialEasing: { height: 'easeOutExpo' }})
      $('.subLvl1').removeClass('open')
    }
    // Gestion ouverture et fermeture
    if ($(this).parent().find('.subLvl1').hasClass('open')) {
      $(this).parent().find('.subLvl1').removeClass('open')
      $(this).parent().find('.subLvl1').stop().animate({ height: 'hide' }, { duration: 400, specialEasing: { height: 'easeOutExpo' }})
      $(this).parent().removeClass('active')
    } else {
      $(this).parent().find('.subLvl1').addClass('open')
      $(this).parent().find('.subLvl1').stop().animate({ height: 'show' }, { duration: 400, specialEasing: { height: 'easeOutExpo' }})
      $(this).parent().addClass('active')
    }
  })
}
