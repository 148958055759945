//-------------------------\\
//---- Classe Parallax ----\\
//-------------------------\\

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Classe permettant d'ajouter des éléments parallax sur une page à l'aide de data dans le code HTML
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export class Parallax {
  constructor() {
    this.tElements = [].slice.call(document.querySelectorAll('[data-parallax]'))
    this.tReferences = []
    this.elementOffset = ''
    this.centreEcran = ''
    this.centreElement = ''
    this.position = ''
    this.mediaQuery = 1300
    this.init()
  }


  init() {
    var self = this

    this.tElements.forEach((e, index) => {
      this.tReferences.push({ e, reference() { self.parallax(e, index) }})
    })

    this.tReferences.forEach((e) => {
      e.reference()
      addEventListener('scroll', e.reference, false)
      addEventListener('DOMContentLoaded', e.reference, false)
      addEventListener('load', e.reference, false)
      addEventListener('resize', e.reference, false)
    })
  }

  update() {
    this.tReferences.forEach((e) => {
      e.reference()
      addEventListener('scroll', e.reference, false)
      addEventListener('DOMContentLoaded', e.reference, false)
      addEventListener('load', e.reference, false)
      addEventListener('resize', e.reference, false)
    })
  }


  // Permet de détruire tout ce que la classe à initialisé
  destroy() {
    this.tElements.forEach((e, index) => { e.style.transform = 'none' })
    this.tReferences.forEach((e) => {
      
      removeEventListener('scroll', e.reference, false)
      removeEventListener('DOMContentLoaded', e.reference, false)
      removeEventListener('load', e.reference, false)
      removeEventListener('resize', e.reference, false)
    })
    this.tElements   = []
    this.tReferences = []
  }
  

  // Détect si l'élément est visible dans le viewport
  parallax(e, index) {
    var old_visible = false
    var visible     = this.isElementInViewport(e)
    var offset      = parseInt(( e.dataset.offset !== undefined ? e.dataset.offset : 0 ))

    if ( window.innerWidth >= this.mediaQuery) {
      if (visible != old_visible) {
        old_visible = visible
        this.elementOffset = e.getBoundingClientRect().top
        this.centreEcran = (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0) + (window.innerHeight / 2)
        this.centreElement = this.elementOffset + ( e.clientHeight / 2 )
        this.position = this.centreEcran - this.centreElement
        e.style.transform = 'translateY(' + ( this.position * (e.dataset.speed / 10) + offset) + 'px)'
      }
    } else {
      e.style.transform = 'none'
      removeEventListener('scroll', e.reference, false)
      removeEventListener('DOMContentLoaded', e.reference, false)
      removeEventListener('load', e.reference, false)
      removeEventListener('resize', e.reference, false)
    }
  }


  // Retourne la condition indiquand si l'élément est visible dans le viewport ou pas
  isElementInViewport(e) {
    var rect = e.getBoundingClientRect()

    return (
      rect.bottom >= 0 &&
      rect.right  >= 0 &&
      rect.top    <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.left   <= (window.innerWidth  || document.documentElement.clientWidth)
    )
  }
}