//-----------------------\\
//--- Carousel Slicks ---\\
//-----------------------\\
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Toutes les fonctions utilisant les carousel de slick se retrouve ici. Ces fonction ont besoin du
// node-module slick carousel pour fonctionner (et le css qui vient avec).
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Importation ----------------------------------------------------------------------------------------------------
import $ from 'jquery'
//-----------------------------------------------------------------------------------------------------------------

// Fonction permettant de détruire un carousel Slick
export function destroySlick(carousel, delai) {
  // Paramètres optionnels
  delai = (typeof delai === 'undefined') ? 1000 : delai

  // Un délai pour que le slick ne se détruise pas avant que la transition de page ne soit apparu
  setTimeout(function() {
    $(carousel).slick('unslick')
  }, delai)
}

export function slickHome() {
  $('.homeSlides').slick({
    arrows: true,
    dots: true,
    infinite: true,
    draggable: true,
    cssEase: 'cubic-bezier(0.7, 0, 0.3, 1)',
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4500,		
    speed: 500,
    rows: 0,
    pauseOnHover: false,
    fade: true,
  })
}

export function slickJobs() {
  $('.jobImages').slick({
    arrows: true,
    dots: false,
    infinite: true,
    draggable: true,
    cssEase: 'cubic-bezier(0.7, 0, 0.3, 1)',
    slidesToScroll: 1,
    slidesToShow: 1,
    autoplay: false,
    autoplaySpeed: 4500,		
    speed: 500,
    rows: 0,
    pauseOnHover: false,
    variableWidth: true,
    prevArrow: '.prec',
    nextArrow: '.next',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          centerMode: true,
        }
      },
    ]
  })


}


export function slickTestimonials() {
  $('.testimonialImages').slick({
    arrows: true,
    dots: false,
    infinite: true,
    draggable: true,
    cssEase: 'cubic-bezier(0.7, 0, 0.3, 1)',
    slidesToScroll: 1,
    slidesToShow: 1,
    autoplay: false,
    autoplaySpeed: 4500,		
    speed: 500,
    rows: 0,
    pauseOnHover: false,
    variableWidth: true,
    prevArrow: '.prec',
    nextArrow: '.next',
    asNavFor: '.testimonialTexts',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          centerMode: true,
        }
      },
    ]
  })

  $('.testimonialTexts').slick({
    arrows: false,
    dots: false,
    infinite: true,
    draggable: true,
    cssEase: 'cubic-bezier(0.7, 0, 0.3, 1)',
    slidesToScroll: 1,
    slidesToShow: 1,
    autoplay: false,
    autoplaySpeed: 4500,		
    speed: 500,
    rows: 0,
    pauseOnHover: false,
    fade: true,
    asNavFor: '.testimonialImages',
    adaptiveHeight: true
  })

}